"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMouseFocus = void 0;
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const actions_1 = require("src/redux/actions");
// PYTHIADEV-6780
function useFocusToQuestion() {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { pageChild, currentPage } = (0, react_redux_1.useSelector)((state) => {
        const { pages, questions, widgets } = state;
        const currentPage = pages.currentPage;
        const pageList = pages.list;
        const pageChild = pageList[currentPage]
            .children
            .find(child => {
            switch (child.type) {
                case 'question':
                    return questions[child.id].visible;
                case 'widget':
                    return widgets[child.id].visible;
                default:
                    return;
            }
        });
        return { pageChild, currentPage };
    });
    (0, react_1.useEffect)(() => {
        if (pageChild) {
            dispatch((0, actions_1.scrollToQuestion)(pageChild.id));
        }
    }, [currentPage]);
}
exports.default = useFocusToQuestion;
function useMouseFocus(onBlur) {
    const [isMouseFocused, setIsMouseFocused] = (0, react_1.useState)(false);
    const handleMouseDown = () => {
        setIsMouseFocused(true);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Tab') {
            setIsMouseFocused(false);
        }
    };
    const handleBlur = (e) => {
        onBlur(e);
        setIsMouseFocused(false);
    };
    return { isMouseFocused, handleMouseDown, handleKeyDown, handleBlur };
}
exports.useMouseFocus = useMouseFocus;
