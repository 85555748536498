"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    :root {
        --main-font: 'YS Text Web', Arial, Helvetica, sans-serif;

        --radiobox-size-m-after-size: 6px;
        --radiobox-size-m-positionTop: 3px;
        --radiobox-size-m-size: 17px;

        /*// цвета дефолтного инпута фон*/
        --view-default-control-background: #fff;
        /*// цвет обрамления невыбранного инпута*/
        --view-default-control-color-border: rgba(0, 0, 0, 0.2);
        /*// обрамление невыбранного инпута*/
        --view-default-control-border: 3px solid var(--view-default-control-color-border);
        /*// цвет фона при инпуте выбранном*/
        --view-default-control-checked-background: #fc0;
        /*// цвет при выбраном контроле внутренного элемента*/
        --view-default-fill-color-internal: #000;

        --view-default-fill-color-action: #ffdb4d;
        --view-default-text-action-color: #000;

        --view-required-asterisk: red;

        //        PAGE

        --page-padding-top: 30px;
        --page-padding-left: 24px;
        --page-padding-bottom: 30px;
        --page-padding-right: 24px;

        --page-padding-mobile-top: 1.5em;
        --page-padding-mobile-left: 1.5em;
        --page-padding-mobile-bottom: 1.5em;
        --page-padding-mobile-right: 1.5em;

        --page-safari-in-telegram-padding-bottom: 6em;

        --page-margin: 3em auto 0;
        --page-margin-mobile: var(--page-margin);

        --page-gap: 36px;

        //        button

        --button-xs-font-size: 13px;
        --button-s-font-size: 13px;
        --button-m-font-size: 15px;
        --button-l-font-size: 17px;
        --button-r-font-size: 1.35em;

        --button-xs-height: 24px;
        --button-s-height: 28px;
        --button-m-height: 32px;
        --button-l-height: 40px;
        --button-r-height: 2.856em;

        --button-xs-padding: 0 10px;
        --button-s-padding: 0 13px;
        --button-m-padding: 0 15px;
        --button-l-padding: 0 18px;
        --button-r-padding: 0 1.36em;
        --button-dependent-padding: 0 0;

        --button-xs-line-height: normal;
        --button-s-line-height: normal;
        --button-m-line-height: normal;
        --button-l-line-height: normal;
        --button-r-line-height: normal;
        --button-dependent-line-height: normal;

        --button-s-text-margin: 0;
        --button-m-text-margin: 0;
        --button-l-text-margin: 0;
        --button-r-text-margin: 0;
        --button-xs-text-margin: 0;

        --button-action-bg: var(--view-default-fill-color-action);
        --button-action-hover-bg: var(--button-action-bg);
        --button-action-selected-bg: var(--button-action-bg);
        --button-action-selected-hover-bg: var(--button-action-bg);
        --button-action-border-width: 0;
        --button-action-border-radius: 3px;
        --button-action-min-width: 0;

        --button-action-margin-top: 2.72em;

        --button-disabled-bg: rgba(0, 0, 0, 0.08);
        --button-disabled-text-color: #000;
        --button-disabled-text-opacity: 0.5;

        --button-focus-visible-border-color: #0060D0;
        --button-font-weight: 400;

        //        INPUTS

        --input-dependent-font-size: 1.5em;
        --input-dependent-line-height: 2.4em;

        --input-xs-font-size: 13px;
        --input-xs-line-height: 24px;

        --input-s-font-size: 13px;
        --input-s-line-height: 28px;

        --input-m-font-size: 15px;
        --input-m-line-height: 32px;

        --input-padding: 0 0.53em;

        --input-border-color-active: var(--view-default-fill-color-action);
        --input-border-color-hovered: var(--view-default-fill-color-action);
        --input-border-color: var(--view-default-control-color-border);

        --input-border-weight: 2px;
        --input-border-width: var(--input-border-weight);
        --input-border-bottom-width: 0;
        --input-border-bottom-margin: 0;
        --input-border-radius: 0;
        --input-focus-visible-border-color: #0060D0;

        --input-restriction-bottom: -2.8em;
        --input-restriction-right: 0;
        --input-restriction-font-size: var(--input-dependent-font-size);
        --input-restriction-line-height: var(--input-dependent-line-height);
        --input-restriction-font-weight: normal;

        --input-restriction-mobile-bottom: -1.8em;
        --input-restriction-mobile-right: var(--input-restriction-right);

        --input-control-color: var(--text-color);

        //        MARKDOWN

        --markdown-href-color: #44b;
        --markdown-href-hover-color: #e00;
        --markdown-href-text-decoration: none;
        --markdown-font-size: 1.8em;
        --markdown-line-height: 1.5;
        --markdown-font-weight: 400;
        --markdown-h1-font-size: 2.4em;
        --markdown-h1-line-height: 1.3;
        --markdown-h1-font-weight: 400;
        --markdown-h2-font-size: 2em;
        --markdown-h2-font-weight: 400;
        --markdown-h2-line-height: 1.3;
        --markdown-h3-font-size: 1.5em;
        --markdown-h3-line-height: 1.2;
        --markdown-h3-font-weight: 400;

        --widget-font-size: var(--markdown-font-size);
        --widget-line-height: var(--markdown-line-height);
        --widget-font-weight: var(--markdown-font-weight);

        //        ERRORS

        --error-color: red;
        --error-font-size: 1.5em;
        --error-line-height: 1.18;
        --error-margin: 1em 3em 0 0;

        //        ATTACHMENTS

        --attachment-justify-content: flex-start;
        --attachment-margin-bottom: 1.5em;

        //        QUESTION LABELS

        --question-label-font-size: 10px;
        --question-label-font-weight: 400;
        --question-label-line-height: 20px;
        --question-label-text-align: start;

        //        SCROLL

        --scroll-margin-right: 0;

        //        SCALE

        --scale-labels-font-size: 1.2em;
        --scale-labels-line-height: normal;
        --scale-labels-margin-bottom: 0;
        --scale-mobile-few-options-font-size: 1.4em;
        --scale-mobile-many-options-font-size: 1.4em;
        --scale-desktop-options-font-size: 14px;
        --scale-option-bg: none;
        --scale-option-border-color: #ccc;
        --scale-option-border-hover-color: #b2b2b2;
        --scale-option-border-radius: 0;
        --scale-option-border-width: 0.1em;
        --scale-option-border-style: solid;
        --scale-option-flex: 1;
        --scale-option-font-size: 1.15em;
        --scale-option-focus-visible-border-color: #0060D0;
        --scale-option-hover-bg: none;
        --scale-option-color: inherit;
        --scale-option-hover-color: inherit;
        --scale-option-line-height: 2.3em;
        --scale-option-width: 100%;
        --scale-options-padding-inline: 0px;
        --scale-many-options-padding-inline: 0px;
        --scale-mobile-many-options-label-width: 100%;
        --scale-mobile-many-options-label-line-height: 2.3em;
        --scale-mobile-many-options-margin-bottom: 0;


        --scale-labels-margin-top: 0;
        --question-caption-font-size: 1.2em;
        --question-caption-font-weight: inherit;
        --question-caption-margin-bottom: 1.2em;
        --question-caption-margin-top: 0;
        --question-caption-padding-left: 0;
        --scale-padding-inline: 0;

        --scale-options-count-2-max-width: auto;
        --scale-options-count-3-max-width: auto;
        --scale-options-max-width: auto;
        --scale-options-position: start;
        --scale-options-inner-position: space-between;

        //        CHOICE

        --choice-option-align-items: flex-start;
        --choice-option-checked-transform: scale(1.5);
        --choice-option-checkmark-color: var(--view-default-fill-color-internal);
        --choice-option-checkmark-left: 7px;
        --choice-option-checkmark-long-side-width: 10px;
        --choice-option-checkmark-short-side-width: 5px;
        --choice-option-checkmark-top: 15%;
        --choice-option-checkmark-weight: 3px;
        --choice-option-control-bg-color: var(--view-default-control-background);
        --choice-option-control-focus-visible-border-color: #0060D0;
        --choice-option-control-disabled-color: var(--view-default-control-color-border);
        --choice-option-control-width: 2.4em;
        --choice-option-flex-direction: row;
        --choice-option-gap: 0;
        --choice-option-height: auto;
        --choice-option-label-font-size: 1.6em;
        --choice-option-label-font-weight: inherit;
        --choice-option-margin-inline-end: 1.5em;
        --choice-option-margin-top: 1.5em;
        --choice-option-padding-inline-start: 1em;
        --choice-option-padding-left: 0;
        --choice-option-padding-top: 0.4em;
        --choice-option-unchecked-bg: unset;
        --multi-choice-option-control-border-radius: 0;

        //        LONG TEXT

        --long-text-textarea-font-size: var(--input-dependent-font-size);
        --long-text-textarea-line-height: inherit;
        --long-text-textarea-min-height: 8em;
        --long-text-textarea-max-height: auto;
        --long-text-padding: var(--input-padding);
        --long-text-textarea-autogrow-top: 8px;

        //        RATING

        --rating-border-active: transparent;
        --rating-border-inactive: #B9B9B9;
        --rating-focus-visible-border-color: #0060D0;
        --rating-border-inactive-hover: var(--rating-border-inactive);
        --rating-fill-active: var(--view-default-control-checked-background);
        --rating-fill-inactive: white;
        --rating-fill-inactive-hover: white;
        --rating-stroke-width: 2;
        --rating-justify-content: end;
        --rating-gap: 10px;

        --rating-options-max-width: auto;
        --rating-options-position: start;
        --rating-options-count-2-max-width: var(--rating-options-max-width);
        --rating-options-count-3-max-width: var(--rating-options-max-width);

        //        EMOTION

        --emotion-label-bg: transparent;
        --emotion-label-border-color: var(--view-default-control-checked-background);
        --emotion-label-border-radius: 0;
        --emotion-label-border-width: 0;
        --emotion-label-font-size: 14px;
        --emotion-label-font-weight: 400;
        --emotion-label-line-height: 20px;
        --emotion-label-margin-top: 8px;
        --emotion-border-radius: 8px;
        --emotion-border-width: 1px;
        --emotion-border-color: var(--view-default-control-checked-background);
        --emotion-bg: transparent;
        --emotion-focus-visible-border-color: #0060D0;
        --emotion-gap: 10px;
        --emotion-container-height: 60px;
        --emotion-container-max-width: none;
        --emotion-container-hover-scale: 1.3;
        --emotion-container-selected-scale: 1.3;
        --emotion-justify-content: space-between;
        --emotion-mobile-gap: 4px;
        --emotion-label-display-desktop: block;
        --emotion-label-display-mobile: none;

        --emotion-options-count-2-width: 100%;
        --emotion-options-count-3-width: 100%;
        --emotion-options-width: 100%;
        --emotion-options-position: start;

        //        FINAL PAGE

        --page-final-font-size: 3.5em;
        --page-final-font-weight: normal;
        --page-final-line-height: normal;
        --page-final-padding: 0;

        //        SPECIAL BUTTONS

        --special-buttons-margin-top: 1em;
        --special-buttons-min-height: auto;
        --special-buttons-min-width: auto;
        --special-button-margin: 0.59em 0 0;
        --special-buttons-other-answer-input-margin-top: 2.3em;

        --button-dependent-font-size: 0.885em;
        --button-dependent-height: 1.888em;
        --button-dependent-text-margin: 0 0.98em;

        --button-normal-bg: #fff;
        --button-normal-hover-bg: var(--button-normal-bg);
        --button-normal-selected-bg: var(--button-action-bg);
        --button-normal-selected-hover-bg: var(--button-action-bg);

        --button-normal-border-radius: 3px;
        --button-normal-border-color: rgba(0, 0, 0, 0.2);
        --button-normal-border-hover-color: rgba(0, 0, 0, 0.3);
        --button-normal-border-selected-color: transparent;
        --button-normal-border-width: 1px;
        --button-normal-min-width: 0;

        //        TOOLTIP

        --tooltip-bg: #000;
        --tooltip-border-color: #000;
        --tooltip-border-radius: 4px;
        --tooltip-border-width: 0;
        --tooltip-text-color: #fff;
        --tooltip-font-size: 14px;
        --tooltip-font-weight: normal;
        --tooltip-line-height: normal;
        --tooltip-padding: 5px;
        --tooltip-shadow: none;
        --tooltip-top: 110%;
    }
    .pythia-surveys-root[data-theme='dark'] {
        --choice-option-checkmark-color: var(--view-default-fill-color-internal);
        --rating-fill-active: var(--view-default-control-checked-background);
        --input-border-color-active: var(--view-default-fill-color-action);
        --emotion-border-color: var(--view-default-control-checked-background);
        --button-action-bg: var(--view-default-fill-color-action);
        --button-action-hover-bg: var(--button-action-bg);
        --button-action-selected-bg: var(--button-action-bg);
        --button-action-selected-hover-bg: var(--button-action-bg);
    }
`;
