"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
function default_1(props) {
    const { size } = props;
    return (0, react_1.css) `
        &.input:hover .input__bottom-border,
        .input__control:hover {
            border-color: var(--input-border-color-hovered);
        }

        .input__control {
            align-items: center;
            background-color: var(--input-control-bg-color);
            border-color: var(--input-border-color);
            border-radius: var(--input-border-radius);
            border-style: solid;
            border-width: var(--input-border-width);
            box-sizing: border-box;
            color: var(--input-control-color);
            display: flex;
            font-family: var(--main-font);
            font-size: var(--long-text-textarea-font-size);
            height: var(--input-${size}-line-height);
            line-height: var(--long-text-textarea-line-height);
            outline: 0;
            padding: var(--input-padding);
            transition-property: border-color, border-width;
            transition: 0.1s ease-out;
            width: 100%;
        }

        .input__bottom-border {
            height: 0;
            border-width: 0 0 var(--input-border-bottom-width);
            border-style: solid;
            border-color: var(--input-border-color);
            border-radius: var(--input-border-radius);
            position: relative;
            margin: var(--input-border-bottom-margin);
        }

        &.input_autogrow.input_textarea .input__bottom-border {
            margin-top: var(--long-text-textarea-autogrow-top);
        }

        .input__control::placeholder {
            color: var(--input-control-placeholder-color);
        }

        .input__control::-moz-placeholder {
            color: var(--input-control-placeholder-color);
            opacity: 1; /*В firefox полупрозрачный по умолчанию*/
        }

        // states

        &.input:focus-within,
        .input__control:active,
        .input__control:focus {
            border-color: var(--input-border-color-active);
        }
        .input__control:focus-visible {
            border-radius: 4px;
            outline: 2px solid var(--input-focus-visible-border-color);
            outline-offset: 4px;
        }
        .input__control_mousefocus:focus-visible {
            outline: none;
        }

        //    sizes

        &.input_textarea:not(.input_autogrow) .input__control {
            min-height: var(--long-text-textarea-min-height) !important;
            resize: vertical;
            padding: var(--long-text-padding);
        }

        .input__textarea-wrapper {
            margin-right: var(--scroll-margin-right);
        }

        .input__textarea-wrapper_autogrow {
            display: grid;
            font: inherit;
        }

        .input__textarea-wrapper_autogrow::after {
            content: attr(data-replicated-value) ' ';
            white-space: pre-wrap;
            visibility: hidden;
            padding-bottom: 10px;
            position: relative;
            top: 15px;
        }

        .input__textarea-wrapper_autogrow .input__control,
        .input__textarea-wrapper_autogrow::after {
            grid-area: 1 / 1 / 2 / 2;
            line-height: var(--long-text-textarea-line-height);
            font-size: var(--long-text-textarea-font-size);
            max-height: var(--long-text-textarea-max-height);
            overflow: auto;
            word-break: break-word;
        }

        .input__textarea-wrapper_autogrow .input__control {
            resize: none;
            overflow: auto;
            position: relative;
            top: var(--long-text-textarea-autogrow-top);
            height: auto;
            padding-right: var(--scroll-padding-right);
        }

        .input__textarea-wrapper > textarea {
            cursor: auto;
        }

        /* For WebKit-based browsers (Chrome before 121.0, Safari) */
        @supports selector(::-webkit-scrollbar) {
            .input__textarea-wrapper > textarea::-webkit-scrollbar {
                width: 4px;
            }
            
            .input__textarea-wrapper > textarea::-webkit-scrollbar-thumb {
                background-color: var(--long-text-textarea-scrollbar-thumb-color);
                border-radius: 8px;
                background-clip: padding-box;
            }
            
            .input__textarea-wrapper > textarea::-webkit-scrollbar-track {
                background-color: var(--long-text-textarea-scrollbar-track-color);
            }
        }

        /* For Firefox, Chrome 121+ */
        @supports (scrollbar-width: thin) {
            .input__textarea-wrapper > textarea {
                scrollbar-width: thin;
                scrollbar-color: var(--long-text-textarea-scrollbar-thumb-color) var(--long-text-textarea-scrollbar-track-color);
                scrollbar-radius: 8px;
            }
        }
    `;
}
exports.default = default_1;
